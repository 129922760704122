<template>
  <div>
    <h3 class="text-center my-4">
      Preguntas frecuentes
    </h3>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1" v-for=" (question,index) in questionList " :key="index">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="'accordion-'+ index" variant="light text-left" v-text="question.question">
          </b-button>
        </b-card-header>
        <b-collapse :id="'accordion-' + index " visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text v-html=" question.anwser"></b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        questionList: [{
            question: '¿Quiénes son los clientes de Ecart?',
            anwser: 'Ecart está pensado para todos, conectamos con clientes de todo el mundo.',
          },
          {
            question: '¿Durante cuánto tiempo puedo ser vendedor?',
            anwser: 'Puedes ser vendedor todo el tiempo que quieras. No hay tiempo límite y siempre tendrás la opción de seguir vendiendo a través de Ecart.',
          },
          {
            question: '¿Qué países apoyan? ¿Puedo ser vendedor si vendo en Estados Unidos, México o Colombia?',
            anwser: 'Sí. El programa está abierto a vendedores de todo el mundo, utilizando diferentes opciones de envío y diferentes transportistas.',
          },
          {
            question: '¿Qué tarifas se aplican para vender en Ecart?',
            anwser: 'Poner tus productos en Ecart es gratis. Luego, cada vez que recibas un pedido, se cobrará un porcentaje del valor del producto. El porcentaje lo establece cada vendedor. La tasa mínima es del 8,5%.',
          },
          {
            question: '¿Cómo recibo el pago de mis ventas en Ecart?',
            anwser: 'Los pagos se liberan 7 días después de que el cliente reciba los artículos. Recibirás el saldo en tu cuenta, y estará disponible para ti en cualquier momento. Todos los pagos a los vendedores de Ecart se realizan a través de PayPal, Tarjeta de Débito o Transferencia Bancaria (las tarifas pueden variar dependiendo de tu banco o entidad procesadora). También puedes consultar el saldo de tu cuenta en cualquier momento en tu panel de control > sección de pagos.',
          },
          {
            question: '¿Cómo puedo cambiar los datos de mi cuenta bancaria?',
            anwser: 'Puede actualizar los datos de tu cuenta bancaria desde el Panel de control > Pagos > sección Mis Cuentas. Para obtener asistencia general sobre la aplicación en relación con la usabilidad o la resolución de problemas, ponte en contacto con support@ecart.com.',
          },
          {
            question: '¿Existe un límite en el número de SKUs que puedo listar en Ecart?',
            anwser: 'No hay límites, puede listar tantos productos como desees.',
          },
          {
            question: '¿Tengo un producto que está desconectado y no sé por qué?',
            anwser: 'Si un producto está fuera de línea puede ser debido a que falta una foto, un precio o una cantidad. Si tienes todas estas opciones correctamente y sigues teniendo un producto OFFLINE contacta con support@ecart.com',
          },
          {
            question: '¿Cómo puedo vender sin stock?',
            anwser: 'Ve al Panel de control > Productos > Información del producto > Activar la opción de vender fuera de stock. También puedes elegir la acción masiva de todos los productos y seleccionar Vender fuera de stock.',
          },
          {
            question: '¿Puede el cliente seleccionar la opción de envío que desee?',
            anwser: 'Sí, cada producto tendrá la opción de envío que tu decidas proporcionar. Puede seleccionar los paqueterías desde el Panel de Control > sección Envío.',
          },
          {
            question: '¿Cuáles son mis opciones de envío de los productos?',
            anwser: 'Hay 2 opciones de envío diferentes: <br> Mensajería asociada: Podrás enviar tus productos directamente a los clientes utilizando nuestros servicios de mensajería asociados. En este caso, nosotros nos encargamos de calcular los gastos de envío y los impuestos. <br> Tu propia logística: Nuestra plataforma te permite utilizar la solución logística que prefieras. Podrá enviar Tus productos y añadir el número de seguimiento al pedido.',
          },
          {
            question: 'He recibido un pedido de un producto que está agotado. ¿Qué debo hacer?',
            anwser: 'Debes de cancelar el pedido y le devolveremos el dinero al comprador. Puede cancelar el pedido directamente desde tu panel de control > Pedidos > Detalles del pedido > Cancelar <br> *Ten en cuenta que la cancelación de muchos pedidos puede afectar significativamente a su reputación.',
          },
          {
            question: '¿Hay alguna restricción que deba tener en cuenta?',
            anwser: 'Sí, cuando subas tus productos debes tener en cuenta las siguientes restricciones: Dimensión y peso: Las restricciones de dimensión y peso según los transportistas. Productos restringidos: Cada país tiene su propia normativa de importación. Asegúrate de tener todos los documentos necesarios para exportar tus productos. ',
          },

          {
            question: '¿Qué necesito para hacer un envío internacional?',
            anwser: 'Factura comercial (BOL) -Etiqueta de envío-Resumen de pedido <br> *Para algunos productos se requiere la Licencia de Exportación (FDA) y la Certificación de la Ley de Control de Sustancias Tóxicas. ',
          },

          {
            question: '¿Cómo puedo obtener el BOL (Bill of Landing) para los pedidos internacionales?',
            anwser: 'Puedes imprimir el BOL después de generar la etiqueta de envío. Los pasos para la actualización se enumeran a continuación: <br> Dashboard > Ordenes <br> Ordenes > Identifica tu pedido internacional <br> Haz clic en "Crear Guía". <br> Identifica el Bill of Landing en el campo relacionado <br> Completa la información precisa > ACTUALIZAR >IMPRIMIR',
          },
          {
            question: '¿Qué puedo hacer cuando mis ventas son lentas?',
            anwser: 'Aunque las ventas fluctuarán naturalmente en función de tu inventario y de la época del año, siempre hay medidas que puedes tomar para maximizar el éxito. Asegúrate de que tus productos están mejorados al 100%, ofreze descuentos en tus productos y no olvides recomendar Ecart como una experiencia de compra única a tus clientes.',
          },
          {
            question: '¿Cómo puedo cambiar la información de mi tienda?',
            anwser: 'Puedes cambiar el nombre de tu tienda tantas veces como quieras. Los pasos para la actualización se enumeran a continuación: <br> Panel de Control > Tienda <br> Tienda > Haz clic en el nombre de tu tienda <br> Información de la tienda > Completa la información de la tienda en el campo correspondiente > ACTUALIZAR',
          },
          {
            question: '¿Quién es responsable de un paquete perdido en tránsito?',
            anwser: 'Los transportistas suelen ser responsables de cualquier pérdida o daño que se produzca en tránsito. Si has vendido un artículo y se pierde de camino a su destino, te recomendamos que colabores con el transportista elegido para localizarlo. Si resulta evidente que no se puede recuperar el paquete, habrá que reembolsar el importe a tu cliente. <br> Si se ha devuelto una compra y parece que se ha perdido en el camino, esto se tratará de forma diferente dependiendo de quién haya comprado/provisto la etiqueta de envío de la devolución. Te recomendamos que te ponga en contacto con support@ecart.com para que se revisen los detalles específicos para los siguientes pasos apropiados.',
          },
          {
            question: '¿Qué ocurre con las fluctuaciones de la moneda?',
            anwser: 'No tienes que preocuparse por las fluctuaciones monetarias. Aunque los clientes pagarán en la moneda local, recibirás tu saldo dependiendo de la moneda de la tienda que haya seleccionado, descontando las tasas aplicables.',
          },

        ]
      }
    }
  }
</script>

<style>

</style>